module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby WordPress Theme","short_name":"Phoenix","start_url":"/","background_color":"#E5E5E5","theme_color":"#E3BB74","display":"minimal-ui","icon":"/Applications/MAMP/htdocs/gatsby/lt-italia/packages/gatsby-wordpress-theme-phoenix/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e47b58cb0781ce4cfe6fb8f48119ecff"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../packages/gatsby-wordpress-theme-phoenix/gatsby-browser.js'),
      options: {"plugins":[],"wordPressUrl":"https://wordpress-319227-1755611.cloudwaysapps.com","frontendUrl":"https://phpstack-319227-1755762.cloudwaysapps.com/"},
    }]
