// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---packages-gatsby-wordpress-theme-phoenix-src-pages-404-js": () => import("./../../../../packages/gatsby-wordpress-theme-phoenix/src/pages/404.js" /* webpackChunkName: "component---packages-gatsby-wordpress-theme-phoenix-src-pages-404-js" */),
  "component---packages-gatsby-wordpress-theme-phoenix-src-templates-archive-index-js": () => import("./../../../../packages/gatsby-wordpress-theme-phoenix/src/templates/archive/index.js" /* webpackChunkName: "component---packages-gatsby-wordpress-theme-phoenix-src-templates-archive-index-js" */),
  "component---packages-gatsby-wordpress-theme-phoenix-src-templates-blog-index-js": () => import("./../../../../packages/gatsby-wordpress-theme-phoenix/src/templates/blog/index.js" /* webpackChunkName: "component---packages-gatsby-wordpress-theme-phoenix-src-templates-blog-index-js" */),
  "component---packages-gatsby-wordpress-theme-phoenix-src-templates-front-page-index-js": () => import("./../../../../packages/gatsby-wordpress-theme-phoenix/src/templates/front-page/index.js" /* webpackChunkName: "component---packages-gatsby-wordpress-theme-phoenix-src-templates-front-page-index-js" */),
  "component---packages-gatsby-wordpress-theme-phoenix-src-templates-page-index-js": () => import("./../../../../packages/gatsby-wordpress-theme-phoenix/src/templates/page/index.js" /* webpackChunkName: "component---packages-gatsby-wordpress-theme-phoenix-src-templates-page-index-js" */),
  "component---packages-gatsby-wordpress-theme-phoenix-src-templates-post-index-js": () => import("./../../../../packages/gatsby-wordpress-theme-phoenix/src/templates/post/index.js" /* webpackChunkName: "component---packages-gatsby-wordpress-theme-phoenix-src-templates-post-index-js" */)
}

